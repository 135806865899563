<template>
    <div class="home max-width">
        <!--        轮播图-->
        <div class="banner flex" @mouseleave="showCate=-1">
            <div class="cate-list flex relative">
                <ul>
                    <li v-for="(item,index) in cateList" :key="index" @mouseenter="showCate=index"
                        :class="{'active':showCate==index}">
                        <p class="fs-16">

                            <span style="margin-right:8%;" class="bold">{{cNum[index]}}</span>{{item.name}}</p>
                        <ul class="flex wrap">
                            <li v-for="(sitem,sindex) in item.charnd" :key="sindex" class="second-cate" v-if="sindex<2">
                                <span class="fs-14">
                                    {{sitem.name}}
                                </span>
                            </li>
                        </ul>
                    </li>
                </ul>
                <div class="third-cate" v-if="showCate!=-1">

                    <div class="flex" v-for="(item,index) in thirdCate.charnd" :key="index">
                        <div class="two right" style="width: 200px;padding-right: 10px;">
                            {{ item.name}}
                        </div>
                        <div class="third flex wrap flex-1">
                            <div v-for="(sitem,sindex) in item.charnds" :key="sindex">
                                <span @click="catePage(sitem.id,index,sindex)"
                                      class="c-pointer">   {{ sitem.name}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="banner-img pointer" style="margin-left: 10px;">
                <el-carousel :interval="5000" arrow="always" height="434px">
                    <el-carousel-item v-for="item in lunbos" :key="item.id">
                        <img :src="domain+item.image" alt="" @click="linkPage(item.url)">
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div class="news-my flex-1 none flex-column">
                <div class="my-select white-back">
                    <div class="flex-cc" v-if="!token">
                        <img src="@/assets/img/avatar.png" alt="" class="avatar"/>
                        <p class="fs-15">欢迎来到医来顺</p>
                    </div>
                    <div v-else class="flex-cc flex-column flex-1">
                        <div class="flex flex-cc margin-40" style="width: 100%;" v-if="userInfo.qiyesuoshu_id==0">
                            <img :src="userInfo.avatar" alt="" class="avatar"/>
                            <p class="fs-15">
                                {{useInfo.nickname?useInfo.nickname:'未设置'}}</p>
                        </div>
                        <div class="flex flex-cc margin-40" style="width: 100%;" v-else>
                            <img src="@/assets/img/132.png" alt="" class="avatar"/>
                            <p class="fs-15">{{companyName}}</p>
                        </div>
                        <p class="fs-15">欢迎来到医来顺</p>
                    </div>
                    <div class="button flex-l-r" v-if="!token">
                        <button type="primary" @click="backAccount('login')">登录</button>
                        <button type="primary" @click="backAccount('register')">注册</button>
                    </div>
                </div>
                <div class="white-back news flex-1">
                    <h1 class="flex-l-r">
                        <p class="fs-18">商城头条</p>
                        <p class="fs-14 pointer" @click="moreNews">更多
                            <i class="iconfont el-icon-arrow-right"></i>
                        </p>
                    </h1>
                    <ul class="pointer">
                        <li v-for="(item,index) in companyNews" :key="index" class="fs-14 c-pointer"
                            @click="articleDetail(item.id)">
                            <p class="line-1 fs-14">
                                {{item.title}}</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <!--    精选品牌-->
        <div class="brands">
            <h1 class="fs-20 center">精选品牌</h1>

            <el-carousel :interval="5000" arrow="always" :autoplay="false">
                <el-carousel-item v-for="(item,index) in brands" :key="index">
                    <div class="pointer more-brands">
                        <div v-for="(sitem,sindex) in item" @click="brandPage(sitem.id)" @mouseenter="brandIndex=sindex"
                             @mouseleave="brandIndex=-1"
                             style="background: white;height: 131px" class="relative"
                        >
                            <img :src="domain+sitem.image" alt="" style="width:100%;height: 100%;object-fit: contain">
                            <div class="brands-name fs-14" v-if="sindex==brandIndex"
                            >{{sitem.name}}
                            </div>
                        </div>
                    </div>
                </el-carousel-item>
            </el-carousel>
            <!--            <ul class="flex pointer">-->
            <!--                <li v-for="(item,index) in brands" :key="index" @click="brandPage(item.id)" class="relative"-->
            <!--                    @mouseenter="brandIndex=index" @mouseleave="brandIndex=-1">-->
            <!--                    <img :src="domain+item.image" alt="">-->
            <!--                    <div class="brands-name fs-14" v-if="index==brandIndex"-->
            <!--                    >{{item.name}}-->
            <!--                    </div>-->
            <!--                </li>-->
            <!--            </ul>-->
        </div>
        <!--        分类-->
        <div class="cate-good">
            <CateGood :list="newProdtcts" :imgPath="imgPath" :small="true" width="215px" height="200px"></CateGood>
            <div class="flex-column all-cate">
                <div v-for="(item,index) in list" :key="item.id" :style="{'order':index}">
                    <div class="title">
                        <h1 class="flex-r-center">
                            <div class="loft">
                                <span>  {{index+1}}</span>
                                <span> F</span>
                            </div>

                            {{item.name}}
                        </h1>
                        <h2>
                        <span v-for="(sitem,sindex) in item.list1.charnds" :key="sitem.id"
                              @click="catePage(sitem.id,index,sindex)">
                        {{sitem.name}}
                    </span></h2>
                    </div>
                    <CateGood :list="item.goods" :imgPath="item.image" :href="item.list2.charnd" :index="index"
                              :id="item.id"></CateGood>
                </div>

                <div class="infomation" style="order:10;">
                    <h1 class="flex-l-r fs-18">
                        行业资讯
                        <span class="g-f pointer" @click="moreNews(73)">更多
                             <i class="iconfont el-icon-arrow-right"/> </span>
                    </h1>
                    <div class="">
                        <ul class="pointer" id="demo">
                            <li v-for="(item,index) in industry" :key="index" class="flex-l-r c-pointer"

                                @click="articleDetail(item.id)">
                                <p>{{item.title}}</p>
                                <p>{{item.time}}</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
    import CateGood from "@/components/CateGood";
    import {gChild_cate, gTypesa, mFist_cate_goods, mLunbotu, mNew, mXingwensa, timeas} from "@/request/api";
    import {domain, fomartDate} from "@/request/utils";
    import imgPath from '@/assets/img/newproduct.png'

    export default {
        components: {CateGood},
        name: "index.vue",
        data() {
            return {

                cNum: ['一', '二', '三', '四','五'],
                showCate: -1,
                domain,
                lunbos: [],
                brands: [],
                cateList: [],
                thirdCate: [],
                industry: [],
                companyNews: [],
                newProdtcts: [],
                imgPath,
                list: [],
                list1: [],
                token: localStorage.getItem('ylsToken'),
                brandIndex: -1,
                useInfo: {},
                companyName: '',
                newBrands1: [],
                brandPages: 1

            }
        },
        watch: {
            showCate(val) {
                this.thirdCate = this.cateList[val]
            }
        },
        methods: {
            linkPage(url) {
                if (url) {
                    window.open(url)
                }
            },
            moreNews(id) {
                this.$router.push({path: 'IndustryConsultancy',})
            },
            articleDetail(id) {
                const url = this.$router.resolve({path: '/articleDetail', query: {id: id}})
                window.open(url.href, '_blank')
            },
            brandPage(id) {
                this.$router.push({path: '/supplierInfo', query: {id}})
            },
            async getLunbo() {
                let res = await mLunbotu();
                this.lunbos = res.data;
            },
            // 获取所有品牌
            getBrands(loading = true) {
                if (loading == false) return
                gTypesa({page: this.brandPages}).then((res) => {
                    this.newBrands1.push(res.data);
                    this.brands = this.newBrands1;
                    if (res.data.length != 0) {
                        this.brandPages++;
                        this.getBrands()
                    } else {
                        this.brands.splice(this.newBrands1.length - 1, 1)
                        this.getBrands(false)
                    }
                })
            },
            backAccount(name) {
                this.$router.push({name})
            },
            catePage(id, index, sindex) {
                if (this.showCate > 0) {
                    localStorage.setItem('cateIndex', [this.showCate, index, sindex])
                } else {
                    console.log(sindex)
                    localStorage.setItem('cateIndex', [index, 0, sindex])
                }
                this.$router.push({path: '/allCate', query: {id}})
            },
            async getCate() {
                let res = await gChild_cate();
                this.cateList = res.data;
                this.thirdCate = this.cateList[0];
                this.getFirstCate();
            },
            async getNewProducts() {
                let res = await mNew();
                let result = []
                for (let i = 0; i < res.data.length; i += 4) {
                    result.push(res.data.slice(i, i + 4));
                }
                this.newProdtcts = result;

            },
            async getXingwen() {
                // 公司新闻
                let res1 = await mXingwensa({id: 74});
                this.companyNews = res1.data.list.filter((item, index) => {
                    return index < 5
                });
                //行业动态
                let res2 = await mXingwensa({id: 73});
                this.industry = res2.data.list.map((item) => {
                    item.time = fomartDate(item.createtime);
                    return item
                });
            },
            async getFirstCate() {
                let res = await mFist_cate_goods({token: localStorage.getItem('ylsToken')});
                res.data.forEach((item, index) => {
                    let result = [];
                    for (let i = 0; i < item.goods.length; i += 4) {
                        result.push(item.goods.slice(i, i + 4));
                    }
                    item.goods = result;
                    item.list1 = this.cateList[index].charnd[0];
                    item.list2 = this.cateList[index];
                })
                this.list = res.data;
            },
            async getTimeas() {
                let res = await timeas();
                if (res.code == 1) {
                    this.companyName = res.data.name;
                }
            }
        },
        created() {
            this.getLunbo();
            this.getXingwen();
            this.getBrands();
            this.getCate();
            this.getNewProducts();
            this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
            if (localStorage.getItem('ylsToken')) {
                this.getTimeas()
            }
        },
    }
</script>

<style scoped lang="scss">
    .home {
        .third-cate {
            position: absolute;
            left: 220px;
            top: 0px;
            width: 980px;
            background: white;
            padding: 30px 30px 0;
            z-index: 9999;
            color: black;

            & > div div:after {
                content: ' ';
                background: #eee;
                width: 93%;
                position: absolute;
                height: 1px;
                left: 30px;
                margin-top: 34px;
            }

            .two {
                height: 34px;
                line-height: 34px;
            }

            .third span {
                height: 34px;
                line-height: 34px;
                margin: 0 20px;
                display: inline-block;

                &:before {
                    content: ' ';
                    background: #eee;
                    width: 1px;
                    position: absolute;
                    height: 22px;
                    margin: 8px 0 0 -20px;
                }
            }
        }
    }

    .home .cate-list {
        height: 434px;

        .third-cate {
            height: 434px;
            overflow: scroll;

            &::-webkit-scrollbar {
                width: 0;
            }
        }

        width: 18.3%;
        background: white;
        color: #333;

        &::-webkit-scrollbar {
            width: 0;
        }

        & > ul > li:first-child {
            margin-top: 15px;
        }

        & > ul > li {
            /*list-style-type: cjk-ideographic;*/
            position: relative;
            padding: 10px 17px;


            ul {
                margin-left: 18%;
            }
            &:hover{
                color:#386FF0;
                cursor: pointer;
            }
        }

        .second-cate {
            margin: 9px 20px 0 0;
        }

        .active {
            background: #fff;
            color: black;
        }
    }

    .home .banner-img {
        width: 59.4%;
        height: 434px;

        img {
            width: 100%;
            height: 100%;
            object-fit: fill;
        }

    }

    /*覆盖element样式*/
    .home {
        /deep/ {
            .el-carousel__arrow--right, .el-carousel__arrow--left {
                border-radius: 0;
                height: 64px;
                width: 44px;
            }

            .el-carousel__arrow i {
                font-size: 25px;
            }
        }
    }

    .home .cate-good {
        /deep/ .el-carousel__indicators--horizontal {
            display: none;
        }
    }

    .news-my {
        margin-left: 0.8%;

        & > div {
            margin-top: 10px;
        }
    }

    .home .my-select {
        width: 100%;
        height: 152px;
        padding: 18px 29px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        img {
            width: 55px;
            height: 55px;
            margin-right: 5%;
        }

        .button {
            button {
                width: 44%;
                height: 32px;
                border: 1px solid $m-c;
                border-radius: 4px;
                font-size: 15px;
                background: none;

                &:first-child {
                    background: $m-c;
                    color: white;
                }

                &:last-child {
                    color: $m-c;
                }
            }
        }
    }

    .home .news {
        h1 {
            font-weight: normal;
            padding-top: 3px;
            height: 56px;
            margin: 0 18px;
            border-bottom: 1px solid #ccc;
        }

        ul {
            padding: 0 18px 2px;

            li {
                margin-top: 20px;
            }
        }
    }

    /*品牌*/
    .home .brands {
        h1 {
            margin: 27px 0 18px;
        }

        ul {
            flex-wrap: wrap;
            background: white;
            border-left: 1px solid #eee;

            li {
                width: 16.666%;
                height: 92px;
                border-right: 1px solid #eee;
                border-top: 1px solid #eee;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }

                .brands-name {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background: rgba(56, 111, 240, 0.7);
                    color: white;
                    text-align: center;
                    line-height: 92px;
                    top: 0;
                }
            }
        }
    }

    .brands-name {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(56, 111, 240, 0.7);
        color: white;
        text-align: center;
        line-height: 130px;
        top: 0;
    }

    /*分类商品*/
    .cate-good {
        margin: 30px 0 30px;

        .title {
            margin: 30px 0 18px;
            display: flex;
            align-items: center;
            justify-content: space-between;

        }

        h1 {
            font-size: 20px;
            font-weight: normal;

            .loft {
                span {
                    font-size: 20px;
                    background: $m-c;
                    color: white;
                    width: 26px;
                    height: 24px;
                    line-height: 24px;
                    display: inline-block;
                    text-align: center;
                }

                span:first-child {
                    font-size: 20px;
                    border-radius: 13px 0 0 13px;
                    padding-left: 4px;

                }

                span:last-child {
                    font-size: 20px;
                    margin: 0 6px;
                    padding-right: 4px;
                    border-radius: 0 13px 13px 0;
                }
            }
        }

        h2 {
            flex: 1;
            text-align: right;
            font-size: 18px;
            font-weight: normal;
            color: $gray;

            span {
                margin-left: 1%;
                cursor: pointer;
            }

            span:hover {
                color: $m-c;
            }
        }
    }

    /*行业资讯*/
    .infomation {
        margin-top: 30px;
        padding: 0 20px 20px;
        background: white;

        li, h1 {
            height: 56px;
        }

        h1 {
            border-bottom: 1px solid $gray;
        }

        li {
            border-bottom: 1px solid #eee;

            p {
                font-size: 15px;
            }
        }
    }

    .all-cate > div:nth-child(1) /deep/ .second-name {
        color: #FDA63D;
    }

    .all-cate > div:nth-child(2) /deep/ .second-name {
        color: #68CED2;
    }

    .all-cate > div:nth-child(3) /deep/ .second-name {
        color: #FD7EA7;
    }

    .all-cate > div:nth-child(4) /deep/ .second-name {
        color: #34A5F1;
    }

    .more-brands {
        display: flex;
        flex-wrap: wrap;

        & > div {
            width: 15% !important;
            margin: 10px;
        }
/*display: grid;*/
/*        grid-template-columns: repeat(6,1fr);*/
/*        grid-template-rows: 1fr 1fr;*/
/*        grid-gap: 20px;*/
/*        grid-auto-flow: column;*/
    }
</style>
