<template>
    <div class="flex white-back" :class="small?'new':'cate'">
        <img :src="imgPath" alt="" v-if="small" class="small-img"/>
        <img :src="domain+imgPath" alt="" v-else class="big-img"/>
        <div class="flex-r-center wrap second-name">
            <span v-for="(item,index) in href" v-if="index<4"  class="pointer"
                  @click="toClassify(item,index)">  {{item.name}}</span>
        </div>

        <el-carousel trigger="click" style="flex:1;" :autoplay="false" :loop="false" v-if="list.length">
            <el-carousel-item v-for="item in list" :key="item.id">

                <div class="flex" :class="small?'new1':'cate1'">
                    <div v-for="sitem in item" :key="sitem.id" @click="detailPage(sitem.id,sitem)"
                         class="pointer good-content">

                        <div class="flex-column">
                            <div class="img">
<!--                                :style="{'width':width,'height':height}">-->
                                <el-image :src="domain+sitem.main_image" class="error-img" fit="cover"
                                          style="width: 220px;height: 220px;">
                                    <div slot="error" class="image-slot">
                                        <img src="@/assets/img/logo.png" alt="" class="logo-img"/>
                                    </div>
                                </el-image>

                            </div>

                            <p class="line-2" style="margin-top: 4px;">
                                <span v-if="sitem.brand">【{{sitem.brand.name}}】</span>
                                {{sitem.name}}</p>
                        </div>
                        <div v-if="sitem.sku">
                            <p class="r-f  price" v-if="sitem.sku[0].group_price>0&&sitem.sku[0].group_price!=sitem.sku[0].market_price">
                                成交价: <span>￥{{sitem.sku[0].group_price}}</span>
                            </p>
                            <p class="r-f  price" v-else>
                                市场价: <span>￥{{sitem.sku[0].market_price}}</span>
                            </p>
                            <p class="g-f fs-12">挂网价：￥{{sitem.sku[0].cost_price}}</p>
                        </div>

                    </div>
                </div>
            </el-carousel-item>
        </el-carousel>
        <el-empty :image="emptyImg" :image-size="300" description="该分类没有商品哦~" class="empty" v-else></el-empty>
    </div>
</template>

<script>
    import {domain} from "@/request/utils";
    import emptyImg from '@/assets/img/no_index.png'

    export default {
        name: "cateGood.vue",
        props: {
            list: {
                type: Array,

            },
            title: {
                type: Array,

            },
            small: {
                type: Boolean,
                default: false
            },
            imgPath: {
                type: String,
            },
            width: {
                type: String,
                default: '200px'
            },
            height: {
                type: String,
                default: '200px'
            },
            href: {
                type: Array,
            },
           id: {
                type: Number,
            },
            index: {
                type: Number,
            },

        },
        data() {
            return {
                domain,
                emptyImg
            }
        },
        methods: {
            toClassify(item, index) {
                let {id} = item.charnds[0];
                localStorage.setItem('cateIndex', [this.index, index,0])
                this.$router.push({path: '/allCate', query: {id}})
            },
            detailPage(id, item) {
                if (item.is_sale == 2) {
                    return this.$message.error('该产品已下架')
                }
                const url = this.$router.resolve({path: '/productDetail', query: {id: id}})
                window.open(url.href, '_blank')
            }
        },
        mounted() {
            return;
            this.list = this.list.forEach((item) => {
                item.forEach((sitem) => {
                    sitem.sku.sort((a, b) => {
                        return b.market_price - a.market_price;
                    })
                })

            })
        }
    }
</script>

<style scoped lang="scss">
    .new {
        & > img {
            width: 230px;
            height: 320px;
        }

        /deep/ .el-carousel__container {
            height: 100%;
        }

        .price + p {
            margin-top: 5px;
        }

        .new1 {
            display: grid;
            height: 100%;
            /*grid-gap:2.2%;*/

            grid-template-columns: repeat(4, 25%);
            /*padding: 20px;*/


            & > div {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }

            img {
                margin-bottom: 10px;
                width: 100%;
                height: 170px;
                object-fit: contain;
            }
        }
    }


    .cate {
        /deep/ .el-carousel__container {
            height: 100%;
        }

        .price + p {
            margin-top: 5px;
        }

        .cate1 {
            height:  320px;
            display: grid;
            /*padding: 20px;*/
            /*grid-gap: 2%;*/

            grid-template-columns: repeat(4, 25%);

            & > div {

                display: flex;
                flex-direction: column;
                justify-content: space-between;

            }


            .img {
                margin-top: 5px;
                width: 100%;
                text-align: center;
                /*height: 170px;*/
            }
        }

        .big-img {
            width: 230px;
            height: 320px;
        }
    }

    .good-content {
        border: 1px solid #eee;
        /*margin: 1px;*/
        padding: 8px 8px 12px 8px;
        position: relative;
        box-sizing: border-box;
    }

    .good-content:nth-last-child(n+1) {
        border-left: 0;
    }

    .good-content:hover {
        box-shadow: 0 0 2px $m-c inset;
    }

    .good-content:hover /deep/ .img img {
        transform: scale(1.2);

        position: relative;
        transition: 1s;
    }
    .second-name{
        position: absolute;width: 170px;margin: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 10px;
        span{
            width: 48%;
            overflow: hidden;

            white-space: nowrap;
            margin-top: 10px;
        }
    }
</style>
